import styled from 'styled-components';

export const SplitPane = styled.section`
    display: grid;
    grid-template-columns: 30% 70%;
    width: 100%;
    margin-top: 80px;
    position: relative;

    @media (max-width: 768px) {
        grid-template-columns: 100%;
        margin-top: 50px;
    }
`
