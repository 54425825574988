import styled from 'styled-components';


export const TeamList = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: 25% 25% 25% 25%;
    
    @media (max-width: 850px) {
        grid-template-columns: 33.3% 33.3% 33.3%;
    }

    @media (max-width: 768px) {
        grid-template-columns: 50% 50%;
        padding: 0 20px;
    }  
`
export const ServiceList = styled.div`
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    padding: 0 5%;

    @media (max-width: 850px) {
        padding: 0 30px;
        grid-template-columns: 50% 50%;
    }

    @media (max-width: 550px) {
        padding: 0 20px;
        grid-template-columns: 100%;
    }
`