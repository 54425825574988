import React from 'react'
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Img from "gatsby-image"
import { colors } from '../style/colors';
import { useSetRecoilState } from 'recoil';
import { showingTeamMember, teamItem } from '../state/atoms';

const Container = styled(motion.div)`
    padding: 0 5% 0 0;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        padding: 10px;
        
    }
`;
const ImageContainer = styled.div`
    margin-bottom: 20px;
    width: 90%;
    position: relative;
    max-height: 75vh;
    height: auto;
    overflow: hidden;

    @media (max-width: 768px) {
        
    }
`;
const Name = styled.h5`
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px;

`;
const Role = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 5px;
`;
const Bio = styled.div`
    margin-top: 20px;

    p {
        font-size: 16px;
        line-height: 1.6;

    }

`;
const PopupBtn = styled.p`
    text-decoration-color: ${colors.accent};
    /* text-decoration: underline; */
    color: ${colors.accent};
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
`;

const TeamItem = ({  big, item, bio, togglePopup }) => {
    
    const toggleMember = useSetRecoilState(showingTeamMember)
    const setTeamMember = useSetRecoilState(teamItem)
    
    const showTeamMember = () => {
        setTeamMember(item)
        toggleMember(true)
    }

    return (
        <Container 
            initial={{opacity: 0, y: 5}}
            animate={{opacity: 1, y: 0}}
        >
            <ImageContainer>
                <Img
                    style={{
                    width: "100%",
                    height: "100%",
                    margin: 0,
                    }}
                    fadeIn
                    objectFit="cover"
                    fluid={item.acf.photo.localFile.childImageSharp.fluid}
                />
                {/* <Image src={item.acf.photo.source_url} /> */}
            </ImageContainer>
            <Name>{item.title}</Name>
            {item.content && <PopupBtn onClick={() => showTeamMember()}>View Bio</PopupBtn>}
            {/* {big && 
            <>
                <Role>{item.acf.role}</Role>
                <Role>{item.acf.honours}</Role>
                <Bio dangerouslySetInnerHTML={{ __html: item.content }}/>
            </>
            } */}
        </Container>
    )
}

export default TeamItem