import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import Layout from '../components/layout';
import { SplitPane } from '../layout/containers';
import { TeamList } from '../layout/lists';
import TeamItem from '../components/team-item';
import { NavTitle } from '../style/typography';
import { graphql } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import { colors } from '../style/colors';
import SEO from '../components/seo';
import TeamMemberPopup from '../components/team/team-popup';

const NavContainer = styled.section`
    padding: 0 10%;
    
    @media (max-width: 768px) {
        padding: 0 30px;
    }
`;
const Content = styled.div`
    position: sticky;
    top: 120px;
`;
const Copy = styled.p`
    font-size: 16px;
    @media (max-width: 768px) {
        max-width: 550px;
    
    }
`;
const CategoryList = styled.ul`
    font-family: "Akkurat";
    list-style: none;
    

    @media (max-width: 768px) {
        position: relative;
        max-width: 550px;
        top: 0;
        margin-left: 30px;
    }
`;
const ItemContainer = styled.div`
    position: relative;
`;
const CategoryItem = styled(motion.li)`
    text-transform: uppercase;
    font-weight: ${props => props.selected ? 'bold' : 600};
    font-size: 16px;
    margin-bottom: 7px;
    cursor: pointer;
`;
const Indicator = styled(motion.div)`
    width: 20px;
    height: 4px;
    border-radius: 2px;
    background: ${colors.accent};
    top: 35%;
    position: absolute;
    left: -30px;
    display: ${props => props.selected ? 'block' : 'none'};
`;

const TeamPage = ({ data }) => {

    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('')
    const [teamList, setTeamList] = useState([])

    useEffect(() => {
        getCategories()
    }, [])

    useEffect(() => {
        //Update the team list whenever the selected category changes
        setTeamList([])
        const currentTeam = data.team.edges.filter(el => el.node.acf.category === selectedCategory)
        setTeamList(data.team.edges.map(el => el.node))

    }, [selectedCategory])

    const getCategories = () => {
        const team = data.team.edges

        const categories = team.map(el => el.node.acf.category)

        const uniqueCategories = [...new Set(categories)]

        setSelectedCategory(uniqueCategories[0])
        setCategories(uniqueCategories)
        
    }

    const _renderTeam = () => {
        let renderItems = []
        for (let i=0; i<teamList.length; i++) {
            renderItems.push(<TeamItem item={teamList[i]} big={selectedCategory==="Leadership"} name={`name ${i}`} />)
        }

        return renderItems
    }

    return (
        <Layout>
            <SEO title="Team" />
            <SplitPane>
                <NavContainer>
                    <Content>
                        <NavTitle>Beston's Team</NavTitle>
                        <Copy>We have expanded our initial offerings of civil and structural engineering to provide a broad range of services within the building industry. Our collaborators and clients span homeowners to the luxury tourism industry to governments and other institutions.</Copy>
                        {/* <CategoryList>
                            {
                                categories.map((category, index) => (
                                    <ItemContainer key={index}>
                                        <Indicator
                                            initial={{x: -5, opacity: 0}}
                                            animate={{x: 0, opacity: 1}}
                                            transition={{duration: 0.2}}
                                            selected={category === selectedCategory} 
                                        />
                                        <CategoryItem
                                            key={index}
                                            active={category === selectedCategory}
                                            onClick={() => setSelectedCategory(category)}
                                        >
                                            {category}
                                        </CategoryItem>

                                    </ItemContainer>
                                ))
                            }
                        </CategoryList> */}

                    </Content>
                </NavContainer>
                <TeamList big={selectedCategory === "Leadership"}>
                    {_renderTeam()}
                </TeamList>
            </SplitPane>
        </Layout>
    )
}

export default TeamPage

export const TeamPageQuery = graphql`
    query TeamQuery {
        team: allWordpressPost(filter: {categories: {elemMatch: {name: {eq: "Team"}}}}, sort: {order: ASC, fields: date}) {
            edges {
                node {
                    title
                    acf {
                        photo {
                            source_url
                            localFile {
                                childImageSharp {
                                    fluid(maxHeight: 1500) {
                                    ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        honours
                        category
                        email
                    }
                    content
                }
            }
}
    }
  
`
